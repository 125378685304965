import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  Icon
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { withSnackbar } from 'material-ui-snackbar-provider';

import customConstants from "Constants";
import { withTranslation } from "react-i18next";
import { ArrowBack } from "@material-ui/icons";

const styles = theme => ({
  wrapper: {
    position: "relative",
    backgroundColor:'#fff'
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class ForgotPassword extends Component {
  state = {
    email: "",
    loading: false
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.setState({loading: true})
    axios.post(customConstants.API_URL + "/open/password/reset", {
        username: this.state.email,
        userCaptchaResponse: ""
    }).then((res) => {
        this.setState({loading: false})
        try {
            if (res.data) {
                this.props.snackbar.showMessage(this.props.t("Check your E-mail"), 'success');
            }
            else {
                this.props.snackbar.showMessage(this.props.t("Check your username"), 'error');
            }
        } catch (e) {
            console.log(e)
        }
    }).catch((e) => {
        this.setState({loading: false})
        console.log(e)
        this.props.snackbar.showMessage(this.props.t("Check your username"), 'error');
    })
  };
  render() {
    let { email } = this.state;
    let { classes } = this.props;

    return (
      <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor:'#021633'}}>
        <div className="p-8">
          <Card className="signup-card position-relative y-center" style={{backgroundColor:'#092A5C'}}>
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/logos/proveid.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12} style={{backgroundColor:'#fff'}}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <h3 style={{marginBottom: "20px"}}>{this.props.t("Forgot Password?")}</h3>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label={this.props.t("E-mail")}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid"
                      ]}
                    />
                    <div className="flex flex-middle">
                      <div className={classes.wrapper} style={{width: "100%"}}>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          type="submit" 
                          disabled={this.state.loading}
                          style={{width: "100%"}}
                        >
                          {this.props.t("Reset Password")}
                        </Button>
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{textAlign: "center", paddingTop: "15px"}}>
                      <Button
                        className="capitalize"
                        onClick={() =>
                          this.props.history.push("/session/sign-in")
                        }
                      >
                        <ArrowBack></ArrowBack>
                        <span>&nbsp;&nbsp;</span>
                        {this.props.t("Return")}
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

export default  withTranslation()(withStyles(styles, { withTheme: true })(withSnackbar()(withRouter(
  ForgotPassword
))));
