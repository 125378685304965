import axios from "axios";
import customConstants from "../../../Constants";

const jsonblobConfig = { responseType: 'blob' };

export const getAllList = (postData) => {
  return axios.post(customConstants.API_URL + "/id/list-with-filter",postData);
};

export const verificationsGrants = () => {
  return axios.get(customConstants.API_URL + "/user/grants/WEB_VERIFICATIONS");
};

export const getAllThresholds = (postData) => {
  return axios.get(customConstants.API_URL + "/configuration/get");
};

export const getAllAwaitingList = (page) => {
  return axios.post(customConstants.API_URL + "/doc", {status: "awaiting", page });
};

export const getConfirmedList = () => {
  return axios.post(customConstants.API_URL + "/doc", {status: "confirmed", page: 0});
};

export const getDeclinedList = () => {
  return axios.post(customConstants.API_URL + "/doc", {status: "declined", page: 0});
};

export const getAllListCustomer = (postData) => {
  return axios.post(customConstants.API_URL + "/customer/with-layer/list",postData);

};

export const getMedia = (id,type) => {
  return axios.post(customConstants.API_URL + "/id/media",{id:id,fileType:type});
}
export const getUserExport = (json) => {
  return axios.post(customConstants.API_URL + "/id/export-pdf-with-tid" ,json,jsonblobConfig);
}

export const getProveResult = (id,type) => {
  return axios.get(customConstants.API_URL + "/id/id-info-with-tid-and-layer/" + id);
}

export const getProveResultMasked = (id,type) => {
  return axios.get(customConstants.API_URL + "/id/id-info-with-tid-and-layer-masked/" + id);
}

export const getCustomerResult = (id) => {
  return axios.get(customConstants.API_URL + "/customer/with-layer/" + id);
}
