import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from 'material-ui-snackbar-provider'
import customConstants from "../../../Constants";
import ThresholdListView from "./ThresholdListView";


let _this;
let page = 0;
let filter = [];
let totalRow = 0;

class ThresholdList extends Component {


    originalList = [];
    state = {
        textfieldValue: "",
        sliderValue: 30,
        list: [],
        loading: false,
        viewMode: "list" // list, grid
    };


    handleInputChange = event => {
        event.persist();
        this.setState(
            {
                textfieldValue: event.target.value
            },
            () =>
                _this.setState({
                    list: this.originalList.filter(item =>
                        item.projectName
                            .toLowerCase()
                            .match(event.target.value.toLowerCase())
                    )
                })
        );
    };

    handleSldierChange = (event, value) => {
        this.setState({
            sliderValue: value
        });
    };

    handleViewChange = view => {
        this.setState({
            viewMode: view
        });
    };

    componentDidMount() {
        _this = this;
    }

    render() {

        return (
            <div className="list m-sm-30">
                {(this.state.loading === true) ?
                    <Backdrop style={{
                        zIndex: 999,
                        color: '#fff'
                    }} open={this.state.loading}><CircularProgress
                        color="secondary"/></Backdrop> : null}
                <ThresholdListView props={this.props}
                              ></ThresholdListView>
            </div>
        );
    }
}

export default withTranslation()(ThresholdList);
