import React, {useEffect} from "react";
import {
    Card,
    Icon,
    MenuItem,
    IconButton,
    Grid,
    Hidden, TextField, FormControl, FormLabel, Switch
} from "@material-ui/core";
import {EgretMenu} from "egret";
import {
   Edit
} from "@material-ui/icons";
import {
    getAllThresholds
} from "./ListService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {showMessage, useHasRoles} from "../../../utils";
import {withTranslation} from 'react-i18next';
import {useSnackbar} from 'material-ui-snackbar-provider'
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import DialogContentText from '@material-ui/core/DialogContentText';
import Draggable from 'react-draggable';
import customConstants from "../../../Constants";
import axios from "axios";
import DeleteIcon from '@material-ui/icons/Delete';
import {DataGrid} from '@material-ui/data-grid';
import Select from "react-select";

let _this;
let lastJson = {};

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));


const ThresholdListView = ({props}) => {
    const isMaskedUser = useHasRoles(['masked_agent']);
    const snackbar = useSnackbar();
    const classes = useStyles(props);
    const [open, setOpen] = React.useState(false);
    const [loading, isLoading] = React.useState(false);
    const [needUpdate, setUpdate] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [controlName, setControlName] = React.useState('');
    const [g4, setG4] = React.useState('');
    const [configName, setConfigName] = React.useState('');
    const [openDeleteConfirm, setOpenConfirmationDialog] = React.useState(false);
    const [page, setPage] = React.useState(0)

    const [showButton, setShowAction] = React.useState(false);

    const [lastList, setList] = React.useState([]);

    const [totalRow, setTotalRow] = React.useState(0);

    const operators = [
        { label: props.t("Scores greater than specified value pass"), value: "greater"},
        { label: props.t("Scores less than specified value pass"), value: "less"},
    ]
    const [selectedOperator, setSelectedOperator] = React.useState(operators[0])

    useEffect(() => {
        getAllData();
        //setOpen(true);
    }, [])

    const getLabel = (_label, _type) => {
        let label = ''
        if (_label === "foreignid") {
            if (_type === "BLUECARD") {
                label = "Blue Card"
            } else if (_type === "FOREIGNIDTYPEA" || _type === "FOREIGNIDTYPEB" || _type === "FOREIGNIDTYPEC") {
                label = "Residence Permit"
            } else if (_type === "FOREIGNIDTYPED") {
                label = "Temporary Protection ID"
            } else if (_type === "GERMANIDCARD") {
                label = "German ID Card"
            } else {
                label = _label
            }
        } else {
            label = _label
        }
        return props.t(label)
    }

    function getAllData() {

        isLoading(true);

        const postData = {
            "pageSize": customConstants.PAGE_SIZE,
            "page": page,
            "orderType": "desc",
            "filter": []
        }


        getAllThresholds(postData).then(response => {

            try {

                let lastList = response.data.confScoreValues;
                lastJson = response.data;
                setTotalRow(response.data.confScoreValues.length)

                lastList.map(function (v, k) {
                    v.id = k;
                });

                if (response.data) {
                    console.log(lastList)
                    setList(lastList);
                }


            } catch (e) {
                console.log(e);
            }

            isLoading(false);


        }).catch(function (e) {
            isLoading(false);

        });
    }


    const columns = [
        {
            field: 'confName', headerName: props.t('Control'),
            width: 440
        },
        {
            field: 'label', headerName: props.t('Label'),
            width: 600,
            renderCell: function (params) {
                if (props.t(params.row?.confName?.split("_")[0]) === params.row?.confName?.split("_")[0]) {
                    return ""
                }
                return props.t(params.row?.confName?.split("_")[0]) + props.t(params.row?.confName?.split("_")[1])
            }
        },
        {
            field: 'scores', headerName: props.t('Acceptance Threshold'), sortable: false,
            width: 150,
            renderCell: function (params) {
                if (params.row.isReverse) {
                    return <strong>
                        {params.row.scores.find(o => o.breaker)?.score}
                    </strong>
                }
                else {
                    return <strong>
                        {params.row.scores[2].score}
                    </strong>
                }

            }
        },
        {
            field: '', headerName: props.t('Actions'), sortable: false,
            width: 150,
            renderCell: function (params) {
                return <>
                    <a
                        className={'action-button'}
                        onClick={() => editThreshold(params)}

                    >
                        <Edit style={{ marginTop: 20 }}/>
                    </a>
                    <Switch defaultChecked={params.row.isActive} onChange={(e) => changeIsActive(params.row.confName, e.target.checked)} />
                </>
            }
        }
    ];


    const handleOpenConfirmDelete = () => {
        setOpenConfirmationDialog(true);
    }


    const handleCloseConfirmDelete = () => {
        setOpenConfirmationDialog(false);
    }

    function removeSelectedData() {

        isLoading(true);
        let currentTransactionIds = [];

        lastList.map(function (v) {
            if (checked.includes(v.id)) {
                currentTransactionIds.push(v.transactionId);
            }
        });

        currentTransactionIds.map(function (v) {

            if (v !== '') {
                axios.delete(customConstants.API_URL + '/id/clean-transaction/' + v, {}).then(response => {

                    if (response.status !== 200) {
                        snackbar.showMessage(props.t('Couldn\'t removed ' + v), 'error');
                    }

                }).catch(error => {
                    isLoading(false);
                    snackbar.showMessage(props.t('Couldn\'t removed ' + v), 'error');
                });
            }

        });
        isLoading(false);
        setOpenConfirmationDialog(false);

        setTimeout(function () {
            getAllData();
        }, 1500);


    }

    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    function editThreshold(params) {
        const op = params.row.isReverse ? "less" : "greater"
        if (op === "less") {
            const index = params.row.scores.findIndex(o => o.breaker)
            setG4(params.row.scores[index].score)
        }
        else {
            setG4(params.row.scores[2].score)
        }

        setConfigName(params.row.confName)

        setSelectedOperator(operators.find(o => o.value === op))
        console.log(operators.find(o => o.value === op))
        setOpen(true)
    }

    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={0} style={{marginTop: 20}}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes =
        {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };


    const getWithPageNumber = (newPage) => {
        page = newPage.page;
        getAllData(page)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseAndSave = () => {

        lastJson.confScoreValues.map(function (v,k) {
            if (v.confName === configName) {
                if (selectedOperator.value === "less") {
                    const index = v.scores.findIndex(o => o.breaker)
                    v.scores[index].score = parseFloat(g4);
                    v.isReverse = true
                }
                else {
                    v.scores[2].score = parseFloat(g4);
                    v.isReverse = false
                }
            }
        })

        delete lastJson.confType;
        delete lastJson.orgId;
        console.log(lastJson);

        axios.post(customConstants.API_URL + '/configuration/update', lastJson).then(function () {
            setOpen(false);
            getAllData();
        })

    }

    const changeIsActive = (_configName, value) => {
        lastJson.confScoreValues.map(function (v,k) {
            if (v.confName === _configName) {
                v.isActive = value;
            }
        })

        delete lastJson.confType;
        delete lastJson.orgId;

        axios.post(customConstants.API_URL + '/configuration/update', lastJson).then(function () {
            getAllData();
        })
    }

    const handleS4 = (event) => {
        setG4(event.target.value);
    };

    return (
        <>
            <Dialog
                open={openDeleteConfirm}
                onClose={handleCloseConfirmDelete}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    {props.t('Delete Confirmation')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.t('The data you selected will be completely removed. Do you want to continue?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseConfirmDelete}
                            style={{backgroundColor: "#999", color: "#fff"}}>
                        {props.t('Cancel')}
                    </Button>
                    <Button onClick={removeSelectedData}
                            style={{boxShadow: "inset 0px 0px 0px 2px #999", color: "#999"}}>
                        {props.t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="list-view">
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogContent dividers style={{ width: 450 }}>
                        <h4>Threshold</h4>
                        <FormControl style={{ width: "100%" }}>
                            <Grid container style={{marginBottom: 5, marginTop: 8}}>
                                <Grid item sm={12} lg={12}>
                                    <FormLabel>{props.t('Control Name')}</FormLabel>
                                    <Grid item sm={12} lg={12}>
                                        <Grid container>
                                            <Grid item sm={12} lg={12}>
                                                <div>{configName}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 5, marginTop: 8}}>
                                <Grid item sm={12} lg={12}>
                                    <FormLabel>{props.t('Acceptance Threshold')}</FormLabel>
                                    <Grid item sm={12} lg={12}>
                                        <Grid container>
                                            <Grid item sm={12} lg={12}>
                                                <TextField type="number" size='normal' onChange={(e) => handleS4(e)}
                                                           value={g4} placeholder={props.t('Value')}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <p>{selectedOperator.label}</p>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            {props.t('Close')}
                        </Button>
                        <Button autoFocus onClick={handleCloseAndSave}
                                style={{
                                    backgroundColor: '#ff9e43',
                                    color: '#fff',
                                }}>
                            {props.t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>


                {(loading === true) ?
                    <Backdrop className={classes.backdrop} open={loading}><CircularProgress
                        color="secondary"/></Backdrop> : null}

                <div style={{height: 'calc(100vh - 180px)', width: '100%'}}>
                    <h4 style={{ marginBottom: 20 }}>{props.t('Thresholds')}</h4>
                    {(lastList !== null && lastList.length > 0) ?
                        <DataGrid rowHeight={60} rows={lastList} columns={columns} pageSize={100}
                                  autoHeight={false}
                                  refresh={true}
                                  rowCount={totalRow}
                                  rowsPerPageOptions={[]}
                                  disableExtendRowFullWidth={false}
                                  disableSelectionOnClick={true}
                                  onSelectionModelChange={(newSelection) => {
                                      setShowAction((newSelection.selectionModel.length > 0));
                                      setChecked(newSelection.selectionModel)
                                  }}
                        /> : null}
                </div>

            </div>
        </>
    );
}


export default withTranslation()(ThresholdListView);
