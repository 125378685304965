import React, {Component} from "react";
import {isMobile} from "utils";
import ListTopbar from "../list/ListTopbar";
import {Box, Card, Dialog, FormControl, FormLabel, Grid, Hidden, Icon, TextField} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from 'material-ui-snackbar-provider'
import customConstants from "../../../Constants";
import CardContent from "@material-ui/core/CardContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Close, Edit} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {GET_NOTIFICATION} from "../../redux/actions/NotificationActions";

let _this;
let page = 0;
let filter = [];
let totalRow = 0;

class SettingsPage extends Component {

    originalList = [];

    constructor(props) {
        super(props);


        this.state = {
            textfieldValue: "",
            sliderValue: 30,
            list: [],
            loading: false,
            viewMode: "list", // list, grid,
            open: false,
        };

        this.name = React.createRef();
        this.surname = React.createRef();
        this.mobile = React.createRef();
    }


    handleInputChange = event => {
        event.persist();
        this.setState(
            {
                textfieldValue: event.target.value
            },
            () =>
                _this.setState({
                    list: this.originalList.filter(item =>
                        item.projectName
                            .toLowerCase()
                            .match(event.target.value.toLowerCase())
                    )
                })
        );
    };

    handleSldierChange = (event, value) => {
        this.setState({
            sliderValue: value
        });
    };

    handleViewChange = view => {
        this.setState({
            viewMode: view
        });
    };

    handleClose = () => {
        this.setState({
            open: !_this.state.open
        });
    };

    componentDidMount() {
        _this = this;
    }

    updateUser = () => {
        // POST /user/update-me

        console.log(this.name);


        let data = {
            "name": this.name.current.value,
            "surname": this.surname.current.value,
            "mobile": this.mobile.current.value
        }


        console.log(data);

        axios.post(customConstants.API_URL + "/user/update-me", data).then(res => {
            _this.setState({
                open: !_this.state.open
            });
        });
    }

    render() {

        return (
            <div className="list m-sm-30">
                {(this.state.loading === true) ?
                    <Backdrop style={{
                        zIndex: 999,
                        color: '#fff'
                    }} open={this.state.loading}><CircularProgress
                        color="secondary"/></Backdrop> : null}
                <h5>{this.props.t('Settings')}</h5>
                <Card>
                    <CardContent
                        style={{backgroundColor: '#f1f1f1', borderRadius: 10, marginTop: 30, position: 'relative'}}>
                        <Box
                            component="div"
                            sx={{display: 'inline-block', mx: '2px', width: '100%'}}
                        >
                            <h6 style={{marginBottom: 30, fontWeight: 'bold'}}>{this.props.t('My Info')}</h6>
                            <button className={'btn btn-primary'}
                                    onClick={this.handleClose}
                                    style={{position: 'absolute', right: 15, top: 15, cursor: 'pointer'}}><Edit style={{
                                verticalAlign: 'sub',
                                fontSize: 20,
                                marginRight: 5
                            }}></Edit>{this.props.t('Edit')}</button>
                            <Grid container spacing={1}>
                                <Grid item md={1}>{this.props.t('Name')}</Grid>
                                <Grid item md={11}>{JSON.parse(localStorage.getItem('auth_user')).displayName}</Grid>
                                <Grid item md={1}>{this.props.t('Surname')}</Grid>
                                <Grid item md={11}>{JSON.parse(localStorage.getItem('auth_user')).displayName}</Grid>
                                <Grid item md={1}>{this.props.t('Phone')}</Grid>
                                <Grid item md={11}>{JSON.parse(localStorage.getItem('auth_user')).email}</Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog onClose={this.handleClose} open={this.state.open}>
                    <DialogTitle id="customized-dialog-title">
                        <div style={{float: 'left'}}>
                            {this.props.t('Update Settings')}
                        </div>
                        <div style={{flex: '1 0 0'}}/>
                        <div style={{float: 'right'}}>
                            <Close style={{cursor: "pointer"}} onClick={this.handleClose}/>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{minWidth: "900px", padding: "0px 24px 24px"}}>
                        <FormControl>
                            <Grid container style={{marginBottom: 5, marginTop: 8}}>
                                <Grid item sm={12} lg={12}>
                                    <FormLabel>{this.props.t('Name')}</FormLabel>
                                    <Grid item sm={12} lg={12}>
                                        <Grid container>
                                            <Grid item sm={12} lg={12}>
                                                <TextField type="text" size='normal' inputProps={{ref: this.name}}
                                                           placeholder={this.props.t('Name')}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 5, marginTop: 8}}>
                                <Grid item sm={12} lg={12}>
                                    <FormLabel>{this.props.t('Surname')}</FormLabel>
                                    <Grid item sm={12} lg={12}>
                                        <Grid container>
                                            <Grid item sm={12} lg={12}>
                                                <TextField type="text" size='normal' inputProps={{ref: this.surname}}
                                                           placeholder={this.props.t('Surname')}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 5, marginTop: 8}}>
                                <Grid item sm={12} lg={12}>
                                    <FormLabel>{this.props.t('E-mail')}</FormLabel>
                                    <Grid item sm={12} lg={12}>
                                        <Grid container>
                                            <Grid item sm={12} lg={12}>
                                                <TextField type="text" size='normal' inputProps={{ref: this.mobile}}
                                                           placeholder={this.props.t('E-mail')}/>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary">
                            {this.props.t('Close')}
                        </Button>
                        <Button autoFocus onClick={this.updateUser}
                                style={{
                                    backgroundColor: '#ff9e43',
                                    color: '#fff',
                                }}>
                            {this.props.t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(SettingsPage);
