import NotFound from "./NotFound";
import Signing from "./Signing";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import CreateUser from "./CreateUser";
import ListUsers from "./ListUsers";
import UserApproval from "./UserApproval";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import Privacy from "./Privacy";
import MFA from "./MFA";
import {authRoles} from "../../auth/authRoles";
import SignUp from "./SignUp";
import ListRoute from "../list/ListRoute";
import {EgretLoadable} from "../../../egret";

const WebSDK = EgretLoadable({
    loader: () => import("../web-sdk/WebSDK")
});

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: {show: false}
    },
    secondarySidebar: {show: false},
    footer: {show: false}
};

const sessionRoutes = [
    {
        path: "/session/login",
        component: Signing,
        settings
    },
    {
        path: "/session/mfa",
        component: MFA,
        settings
    },
    {
        path: "/session/sign-in",
        component: SignIn,
        settings
    },
    {
        path: "/session/forgot-password",
        component: ForgotPassword,
        settings
    },
    {
        path: "/session/reset-password",
        component: ResetPassword,
        settings
    },
    {
        path: "/session/create-password",
        component: ResetPassword,
        settings
    },
    {
        path: "/session/change-password",
        component: ChangePassword,
        settings
    },
    {
        path: "/session/create-user",
        component: CreateUser,
        auth: authRoles.admin,
        settings
    },
    {
        path: "/session/users",
        component: ListUsers,
        settings
    },
    {
        path: "/session/user-approval",
        component: UserApproval,
        auth: authRoles.admin,
        settings
    },
    {
        path: "/privacy",
        component: Privacy,
        settings
    }
];

export default sessionRoutes;
