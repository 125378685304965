import React from 'react'

export default function Privacy(props) {
  return (
    <>
    <div className="flex flex-middle brand" style={{padding: "20px", backgroundColor: "#092A5C"}}>
      <img onClick={() => props.history.push('/')} style={{cursor: 'pointer'}} 
        src="/assets/images/logos/proveid.svg" alt="company-logo"/>
    </div>
    <section style={{padding: "30px 20px"}}>
      
      <h5>OUR COMMITMENT TO YOU AND THE PROTECTION OF YOUR DATA</h5>
      <p>Beginning May 25, 2018 the ‘General Data Protection Regulation’ or GDPR will be enforced
        across all EU member states. This compliance regulation intends to unify legislation
        around how personal data is used and managed, leading to more standardized protections
        for all. Under GDPR, consumers will benefit from increased privacy protections for their
        personal data.</p>
      <p>Here’s how Techsign will implement GDPR compliance:</p>
      <ul>
        <li>Organizational Readiness at Techsign</li>
        <li>Security Infrastructure and Certifications</li>
        <li>Product Readiness - Techsign DOC</li>
        <li>Additional Materials</li>
      </ul>
      <h5>TRAINING AND PRIVACY AWARENESS</h5>
      <p>All Techsign employees have been given GDPR training, overseen by our on-site compliance
        team. Training sessions are conducted upon hire for all new employees and annually
        thereafter.</p>
      <h5>DATA MAPPING AND PRIVACY IMPACT ASSESSMENT</h5>
      <p>To verify that our privacy practices are appropriate, Techsign conducted an initial data
        mapping exercise. This included a Privacy Impact Assessment (PIA) to assess how we
        collect, process, and store personal data and determine potential privacy impacts.</p>
      <h5>INFORMATION SECURITY POLICIES</h5>
      <p>Techsign has informational security and data protection policies governing how and when
        employees and contractors can access your data.</p>
      <h5>DATA TRANSFER</h5>
      <p>Techsign participates in and has certified its compliance with the EU-US Privacy Shield
        Framework and the Swiss-U.S. Privacy Shield Framework. Techsign is responsible for the
        processing of personal data it receives, under each Privacy Shield Framework, and
        subsequently transfers data to a third party acting as an agent on its behalf. Techsign
        complies with the Privacy Shield Frameworks for all onward transfers of personal data
        from the EU and Switzerland, including the onward transfer liability provisions.</p>
      <h5>INCIDENT RESPONSE</h5>
      <p>Our Incident Response procedures have been designed and tested to ensure potential
        security events are identified and reported to appropriate personnel for resolution,
        personnel follow defined protocols for resolving security events, and steps for
        resolution are documented and reviewed by the InfoSec Team on a regular basis.
        Additionally our policies and procedures include breach notification for if and when a
        security incident involves the loss of or unauthorized use of personal identifiable
        information (PII).</p>
      <h5>PRODUCT REVIEWS</h5>
      <p>Our Software Development Lifecycle (“SDLC”) ensures that System changes are performed in
        accordance with GDPR requirements, including considerations for Privacy in the following
        areas:</p>
      <ul>
        <li>Planning</li>
        <li>Change Documentation</li>
        <li>Development of Test Plans</li>
        <li>Change Testing and Documentation of Results</li>
        <li>Quality Assurance ("QA") Review and Approval</li>
        <li>Third-party Review and attestation</li>
        <li>Periodic review and update</li>
      </ul>
      <h5>ENDOR REVIEWS</h5>
      <p>All our current sub-processors are reviewed on an annual basis to ensure they meet
        security and privacy requirements required for GDPR.</p>
      <h5>CONTRACTUAL PROTECTIONS</h5>
      <p>Techsign created a GDPR-ready Data Processing Agreement should you require EU-compliant
        contractual protections. Please feel free to reachout to info@techsign.com.tr to request
        a Data Processing Agreement.</p>
      <h5>LIST OF AUTHORIZED SUB-PROCESSORS</h5>
      <p>At Techsign, we understand the serious ramifications of compliance and have diligently
        built processes to make our service compliant with the standards which govern your
        business.</p>
      <ul>
        <li>SOC 2 Type II</li>
        <li>HIPAA</li>
        <li>The U.S. ESIGN act of 2000</li>
        <li>The Uniform Electronic Transactions Act (EUTA) of 1999</li>
        <li>The new eIDAS regulation for the EU of 2016 (EU Regulation 910/2014), which replaces
          the former European EC/1999/93 Directive
        </li>
        <li>Privacy Shield</li>
        <li>Product Security</li>
        <li>‍ENCRYPTION</li>
      </ul>
      <p>By default, communication with our services uses Transport Layer Security (TLS), which is
        regularly updated to use the latest ciphersuites and TLS configurations. Additionally we
        encrypt all customer data at rest using AES 256-T.</p>
      <h5>DATA DELETION AND ACCESS</h5>
      <p>GDPR gives consumers the legal right to request access to and request the deletion of
        personal data stored by a company. We do allow our customers to delete their data from
        our products whenever processing is complete, legally binding retention requirements are
        met, and all parties associated with the artifact in question have agreed to its
        deletion. Please feel free to contact info@techsign.com.tr to initiate a data deletion
        event.</p>
      <h5>COOKIE COMPLIANCE</h5>
      <p>At Techsign we mostly use "session cookies" that are automatically deleted after each
        visit. These cookies permit us to recognize users and avoid repetitive requests for the
        same information. However, cookies can be uniquely attributed to a device and therefore
        they are capable of identifying an individual. As such, we've reviewed all of our
        cookies to ensure the required consent is gathered and that they are treated as PII when
        appropriate.</p>
    </section>
    </>
  )
}