import React from "react";
import {Redirect} from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import ListRoute from "./views/list/ListRoute";


const redirectRoute = [];


try {

    let currentGrants = JSON.parse(localStorage.getItem('grants'));

    if (currentGrants !== null) {
        currentGrants.map(function (v, k) {

            if (k === 0) {
                redirectRoute.push(
                    {
                        path: "/",
                        exact: false,
                        component: () => <Redirect to={window.redirect_uri}/>,
                    }
                )
            }

        });
    }


} catch (e) {
    console.log(e);
}

const errorRoute = [
    {
        component: () => <Redirect to="/session/login"/>
    }
];



const routes = [
    ...ListRoute,
    ...sessionRoutes,
    ...redirectRoute
];


export default routes;
