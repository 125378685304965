import i18n from "i18next";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArticleIcon from '@mui/icons-material/Article';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Assignment, AssignmentTurnedIn, FormatListBulleted, Settings } from "@material-ui/icons";

var navigationsAvailable = [
    {
        name: i18n.t("Video Call"),
        icon: SupportAgentIcon,
        path: "/calls/video_call",
        id: "awaiting_calls"
    },
    {
        name: i18n.t("Verifications"),
        icon: FormatListBulleted,
        path: "/users/list",
        id: "verifications"
    },
    {
        name: i18n.t("Pending Verifications"),
        icon: PendingActionsIcon,
        path: "/video_call/controls",
        id: "pending_verifications"
    },
    {
        name: i18n.t("Awaiting Documents"),
        icon: Assignment,
        path: "/documents/awaiting",
        id: "awaiting_documents"
    },
    {
        name: i18n.t("Confirmed Documents"),
        icon: AssignmentTurnedIn,
        path: "/documents/confirmed",
        id: "approved_documents"
    }
];

/*
const agent = useHasRoles(["agent"]);
const agentLead = useHasRoles(["agent_lead"]);
const hasMakerRole = useHasRoles(["maker"]);
const hasCheckerRole = useHasRoles(["checker"]);
const hasAgentRole = useHasRoles(["agent"]);
const hasAgentLeadRole = useHasRoles(["agent_lead"]);
const hasAdminRole = useHasRoles(["admin"]);
*/
let lastNavigations = [];
let currentGrants = JSON.parse(localStorage.getItem('grants')) || [];

navigationsAvailable.map(function (v, k) {
    if (currentGrants.includes(v.id)) {
        lastNavigations.push(v);
    }
})

export const navigations = lastNavigations;

export const navLinks = [
    {
        name: i18n.t("Agent"),
        icon: SupportAgentIcon,
        auth: true,
        sections: [
            {
                name: i18n.t("Video Call"),
                path: "/calls/video_call",
                auth: currentGrants.includes("awaiting_calls"),
            },
        ]
    },
    {
        name: i18n.t("Back Office"),
        icon: ArticleIcon,
        auth: true,
        sections: [
            {
                name: i18n.t("Awaiting Documents"),
                path: "/documents/awaiting",
                auth: currentGrants.includes("awaiting_documents"),
            },
            {
                name: i18n.t("Confirmed Documents"),
                path: "/documents/confirmed",
                auth: currentGrants.includes("approved_documents")
            },
        ]
    },
    {
        name: i18n.t("Supervisor"),
        icon: AssignmentTurnedIn,
        auth: true,
        sections: [
            {
                name: i18n.t("Verifications"),
                path: "/users/list",
                auth: currentGrants.includes("verifications")
            },
            {
                name: i18n.t("Pending Verifications"),
                path: "/video_call/controls",
                auth: currentGrants.includes("pending_verifications")
            },
            {
                name: i18n.t("External Customers"),
                path: "/customers/external",
                auth: currentGrants.includes("external_customers")
            },
        ]
    },

    {
        name: i18n.t("Admin"),
        icon: Settings,
        auth: true,
        sections: [
            {
                name: i18n.t("User Management"),
                auth: true,
                sections: [
                    {
                        name: i18n.t("Create User"),
                        path: "/session/create-user",
                        auth: currentGrants.includes("create_user"),
                    },
                    {
                        name: i18n.t("Approve Users"),
                        path: "/session/user-approval",
                        auth: currentGrants.includes("approve_user"),
                    },
                    {
                        name: i18n.t("All Users"),
                        path: "/users/all",
                        auth: currentGrants.includes("all_users"),
                    },
                    {
                        name: i18n.t("Users To Be Deleted"),
                        auth: currentGrants.includes("users_deleted"),
                        path: "/users/will-be-deleted",
                    },
                    {
                        name: i18n.t("Users To Be Cancelled"),
                        auth: currentGrants.includes("users_cancelled"),
                        path: "/users/will-be-cancelled",
                    }, {
                        name: i18n.t("Users To Be Activated"),
                        auth: currentGrants.includes("users_cancelled"),
                        path: "/users/will-be-activated",
                    },
                    {
                        name: i18n.t("Users To Be Updated"),
                        auth: currentGrants.includes("users_updated"),
                        path: "/users/will-be-updated",
                    },
                ]
            },
            {
                name: i18n.t("Reports"),
                auth: true,
                sections: [
                    {
                        name: i18n.t("Verification Reports"),
                        path: "/reports/verification",
                        auth: currentGrants.includes("verification_reports"),
                    },
                    {
                        name: i18n.t("Thresholds"),
                        auth: currentGrants.includes("thresholds"),
                        path: "/thresholds",
                    },
                    {
                        name: i18n.t("Report Settings"),
                        auth: currentGrants.includes("reports_settings"),
                        path: "/reports/settings",
                    },
                ],
            },
            {
                name: i18n.t("Video Call Management"),
                auth: true,
                sections: [
                    {
                        name: i18n.t("Edit Video Call Scripts"),
                        path: "/video_call/scripts",
                        auth: currentGrants.includes("edit_video_call_scripts"),
                    },
                ]
            },
            {
                name: i18n.t("Video Call Reports"),
                auth: true,
                sections: [
                    {
                        name: i18n.t("Queue Based Call History Report"),
                        path: "/reports/queue",
                        auth: currentGrants.includes("reports_queue"),
                    },
                    {
                        name: i18n.t("Call Statistics Based On Agent"),
                        path: "/reports/agent",
                        auth: currentGrants.includes("reports_agent"),
                    },
                    {
                        name: i18n.t("Queue Based Call Detail"),
                        path: "/reports/queue_meeting_search",
                        auth: currentGrants.includes("queue_meeting_search"),
                    },
                    {
                        name: i18n.t("Call Details Based On Agent"),
                        path: "/reports/agent_meeting_search",
                        auth: currentGrants.includes("agent_meeting_search"),
                    },
                ],
            },

            // {
            //     name: i18n.t("Role Management"),
            //     auth: hasAdminRole,
            //     sections: [
            //         {
            //             name: i18n.t("Roles"),
            //             path: "/roles",
            //             auth: true,
            //         },
            //     ]
            // },
        ]
    },
];
