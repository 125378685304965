import React, {Component, Fragment} from "react";
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    withStyles,
    CircularProgress,
    Icon
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {withRouter} from "react-router-dom";

import {loginWithEmailAndPassword} from "../../redux/actions/LoginActions";
import {withTranslation} from "react-i18next";
import customConstants from "../../../Constants";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const styles = theme => ({
    wrapper: {
        position: "relative"
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

let _this;

class SignIn extends Component {
    state = {
        email: localStorage.getItem("rememberMe") === "true" ? JSON.parse(localStorage.getItem("auth_user"))?.email || "" : "",
        password: "",
        agreement: true,
        showPassword: false,
        emailInput: 'email',
        passwordInput: 'password'
    };
    handleChange = event => {
        function strip(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }

        if (customConstants.REMEMBER_USER === true) {
            event.persist();
            this.setState({[event.target.name]: event.target.value});
        } else {
            if (event.type == 'paste') {
                const clipboardData = event.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData("Text");
                this.setState({[event.target.id]: strip(pastedData)});
            } else {
                this.setState({[event.target.id]: event.target.innerHTML});
            }
        }
    };
    handleRememberMe = event => {
        this.setState({
            agreement: !this.state.agreement
        });
    };
    handleFormSubmit = event => {
        localStorage.setItem("rememberMe", this.state.agreement);
        this.props.loginWithEmailAndPassword({...this.state});
    };

    componentDidMount() {
        _this = this;

        setTimeout(function () {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const isTimeOut = urlParams.get('timeout')

            if (isTimeOut == 'true') {
                alert(_this.props.t("Your session timed out. Please re-login"));
            }
        },1000)

    }

    render() {
        let {email, password, showPassword, emailInput, passwordInput} = this.state;
        let {classes} = this.props;
        return (
            <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor: '#021633'}}>
                <div className="p-8">
                    <Card className="signup-card position-relative y-center" style={{backgroundColor: '#092A5C'}}>
                        <Grid container>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <div className="p-32 flex flex-center flex-middle h-100">
                                    <img src="/assets/images/logos/proveid.svg" alt=""/>
                                </div>
                            </Grid>
                            <Grid item lg={7} md={7} sm={7} xs={12} style={{backgroundColor: '#fff'}}>
                                <div className="p-36 h-100 bg-light-gray position-relative">
                                    <ValidatorForm ref="form" autoComplete="off" style={{position: 'relative'}}
                                                   onSubmit={this.handleFormSubmit}>
                                        {(customConstants.REMEMBER_USER === true) ?
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label={this.props.t("User")}
                                                onChange={this.handleChange}
                                                type="text"
                                                name={emailInput}
                                                id={emailInput}
                                                value={email}
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required",
                                                    "email is not valid"
                                                ]}
                                            /> :
                                            <><label
                                                style={{
                                                    fontSize: 10,
                                                    color: '#000',
                                                    fontWeight: 'bold',
                                                    top: -8,
                                                    left: 15,
                                                    position: 'absolute',
                                                    backgroundColor: '#EBEBEB',
                                                    padding: '0 5px'
                                                }}>{this.props.t("User")}</label>
                                                <div ref="username" onKeyUp={e => this.handleChange(e)}
                                                     className={' MuiOutlinedInput-input'}
                                                     id={'email'}
                                                     onPaste={e => this.handleChange(e)}
                                                     contentEditable="true"
                                                     onKeyDown={evt => {
                                                         if (evt.keyCode === 13) {
                                                             evt.preventDefault();
                                                         }
                                                     }}
                                                     style={{
                                                         backgroundColor: '#EBEBEB',
                                                         border: '1px #ccc solid',
                                                         margin: '0 20',
                                                         borderRadius: 3,
                                                         padding: '15px',
                                                         marginBottom: 10,
                                                         maxHeight: 50,
                                                         overflow: 'hidden',
                                                         whiteSpace: 'nowrap'
                                                     }}></div>
                                            </>}

                                        <div style={{position: "relative"}}>
                                            {(customConstants.REMEMBER_USER === true) ?
                                                <><TextValidator
                                                    className="mb-16 w-100"
                                                    label={this.props.t("Password")}
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    name={passwordInput}
                                                    id={passwordInput}
                                                    /*autoComplete={((customConstants.REMEMBER_USER === false) ? 'new-password' : 'password')}*/
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                /> <span style={{
                                                    position: "absolute",
                                                    right: "15px",
                                                    top: "15px",
                                                    cursor: "pointer"
                                                }}
                                                         onClick={() => this.setState({showPassword: !showPassword})}
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </span></> :
                                                <><label style={{
                                                    fontSize: 10,
                                                    color: '#000',
                                                    top: -8,
                                                    left: 15,
                                                    position: 'absolute',
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#EBEBEB',
                                                    padding: '0 5px'
                                                }}>{this.props.t("Password")}</label>
                                                    <div ref="password" onKeyUp={e => this.handleChange(e)}
                                                         onKeyDown={evt => {
                                                             if (evt.keyCode === 13) {
                                                                 evt.preventDefault();
                                                                 this.handleFormSubmit();
                                                             }
                                                         }}
                                                         onPaste={e => this.handleChange(e)}
                                                         className={' MuiOutlinedInput-input'}
                                                         id={'password'}
                                                         contentEditable="true" style={{
                                                        border: '1px #ccc solid',
                                                        margin: '0 20',
                                                        backgroundColor: '#EBEBEB',
                                                        borderRadius: 3,
                                                        padding: '15px',
                                                        marginBottom: 10,
                                                        '-webkit-text-security': (showPassword === false) ? 'disc' : 'inherit',
                                                        '-moz-text-security': (showPassword === false) ? 'disc' : 'inherit'
                                                    }}></div>
                                                    <span contentEditable={false} style={{
                                                        position: "absolute",
                                                        right: "15px",
                                                        top: "15px",
                                                        cursor: "pointer"
                                                    }}
                                                          onClick={() => this.setState({showPassword: !showPassword})}
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </span>
                                                </>}

                                        </div>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <FormControlLabel
                                                className="mb-8"
                                                name="agreement"
                                                onChange={this.handleRememberMe}
                                                control={<Checkbox checked={this.state.agreement}/>}
                                                label={this.props.t("Remember Me")}
                                            />
                                            <Button
                                                style={{marginTop: "-6.5px"}}
                                                onClick={() =>
                                                    this.props.history.push("/session/forgot-password")
                                                }
                                            >
                                                {this.props.t("Forgot Password?")}
                                            </Button>
                                        </div>
                                        <div className="flex flex-middle mb-8">
                                            <div className={classes.wrapper} style={{width: "100%"}}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={this.props.login.loading}
                                                    type="submit"
                                                    style={{width: "100%"}}
                                                >
                                                    {this.props.t("Sign In")}
                                                </Button>
                                                {this.props.login.loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    login: state.login
});
export default withTranslation()(withStyles(styles, {withTheme: true})(
    withRouter(
        connect(
            mapStateToProps,
            {loginWithEmailAndPassword}
        )(SignIn)
    )
));
