import React, { Component } from "react";
import {
    Card,
    Grid,
    Button,
    withStyles,
    CircularProgress,
    Icon
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { withSnackbar } from 'material-ui-snackbar-provider';
import { withTranslation } from 'react-i18next';
import jwtAuthService from "../../services/jwtAuthService";


import customConstants from "Constants";
import { ArrowBack, Visibility, VisibilityOff } from "@material-ui/icons";

const styles = theme => ({
    wrapper: {
        position: "relative",
        backgroundColor: '#fff'
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class ChangePassword extends Component {
    state = {
        password1: "",
        password2: "",
        oldPassword: "",
        showPassword1: false,
        showPassword2: false,
        showOldPassword: false,
        loading: false,
        needUpdate: false
    };
    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleFormSubmit = () => {
        if (this.state.password1 !== this.state.password2) {
            this.props.snackbar.showMessage(this.props.t("Please enter same password"), 'warning');
            return;
        }

        let payload = {
            newPassword: this.state.password1,
            oldPassword: this.state.oldPassword
        }

        if (this.state.needUpdate) {
            payload['username'] = localStorage.getItem('last_user_attemp','')
        }

        this.setState({loading: true})
        axios.post(((this.state.needUpdate) ? customConstants.API_URL + '/open/password/change-with-username' : customConstants.API_URL + "/user/password"),payload ).then((res) => {
            this.setState({loading: false})
            try {
                if (res.data) {
                    jwtAuthService.logout();
                    this.props.history.push("/session/sign-in")
                } else {
                    this.props.snackbar.showMessage((res?.response?.data?.message) ? res?.response?.data?.message : this.props.t("Could not change your password"), 'error');
                }
            } catch (e) {
                console.log(e)
            }
        }).catch((e) => {
            console.log(e)
            this.setState({loading: false})
            this.props.snackbar.showMessage(this.props.t("Could not change your password"), 'error');
        })
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.location.search)
        this.setState({needUpdate: (params.get('expired') === 'true')})
    }

    render() {
        let {password1, password2, oldPassword, showPassword1, showPassword2, showOldPassword} = this.state;
        let {classes} = this.props;

        return (
            <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor: '#021633'}}>
                <div className="p-8">
                    <Card className="signup-card position-relative y-center" style={{backgroundColor: '#092A5C'}}>
                        <Grid container>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <div className="p-32 flex flex-center flex-middle h-100">
                                    <img src="/assets/images/logos/proveid.svg" alt=""/>
                                </div>
                            </Grid>
                            <Grid item lg={7} md={7} sm={7} xs={12} style={{backgroundColor: '#fff'}}>
                                <div className="p-36 h-100 bg-light-gray position-relative">
                                    <h3 style={{marginBottom: "20px"}}>{(this.state.needUpdate) ? this.props.t("Set New Password") : this.props.t("Change Password")}</h3>
                                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                        <div style={{position: "relative"}}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={this.props.t("Old Password")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="oldPassword"
                                                type={showOldPassword ? "text" : "password"}
                                                value={oldPassword}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                            <span style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "15px",
                                                cursor: "pointer"
                                            }}
                                                  onClick={() => this.setState({showOldPassword: !showOldPassword})}
                                            >
                        {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                      </span>
                                        </div>
                                        <div style={{position: "relative"}}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={this.props.t("New Password")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="password1"
                                                type={showPassword1 ? "text" : "password"}
                                                value={password1}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                            <span style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "15px",
                                                cursor: "pointer"
                                            }}
                                                  onClick={() => this.setState({showPassword1: !showPassword1})}
                                            >
                        {showPassword1 ? <VisibilityOff/> : <Visibility/>}
                      </span>
                                        </div>
                                        <div style={{position: "relative"}}>
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={this.props.t("Confirm New Password")}
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                name="password2"
                                                type={showPassword2 ? "text" : "password"}
                                                value={password2}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                            />
                                            <span style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "15px",
                                                cursor: "pointer"
                                            }}
                                                  onClick={() => this.setState({showPassword2: !showPassword2})}
                                            >
                        {showPassword2 ? <VisibilityOff/> : <Visibility/>}
                      </span>
                                        </div>
                                        <div className="flex flex-middle">
                                            <div className={classes.wrapper} style={{width: "100%"}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={this.state.loading}
                                                    style={{width: "100%"}}
                                                >
                                                    {this.props.t("Save Password")}
                                                </Button>
                                                {this.state.loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {(!this.state.needUpdate) ?
                                            <div style={{textAlign: "center", paddingTop: "15px"}}>
                                                <Button
                                                    className="capitalize"
                                                    onClick={() =>
                                                        this.props.history.push("/")
                                                    }
                                                >
                                                    <ArrowBack></ArrowBack>
                                                    <span>&nbsp;&nbsp;</span>
                                                    {this.props.t("Return")}
                                                </Button>
                                            </div> : null}
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation()(withStyles(styles, {withTheme: true})(withSnackbar()(
    ChangePassword
))));
