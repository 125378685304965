import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import {setUserData} from "./UserActions";


import history from "history.js";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";


export function loginWithEmailAndPassword({email, password}) {


    return dispatch => {


        dispatch({
            type: LOGIN_LOADING
        });

        jwtAuthService
            .loginWithEmailAndPassword(email, password)
            .then(user => {


                dispatch(setUserData(user));

                history.push({
                    pathname: window.redirect_uri
                });

                return dispatch({
                    type: LOGIN_SUCCESS
                });
            })
            .catch(error => {

                if (error?.response?.data?.error_description.trim() === 'user_password_expired') {
                    alert('Your password has been expired. Please reset your password first')
                    localStorage.setItem('last_user_attemp',email)
                    window.location = '/session/change-password?expired=true'
                } else {
                    alert(error?.response?.data?.error_description || 'Please check your login credentials')
                }


                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error?.response
                });
            });
    };
}

export function resetPassword({email}) {
    return dispatch => {
        dispatch({
            payload: email,
            type: RESET_PASSWORD
        });
    };
}

export function firebaseLoginEmailPassword({email, password}) {
    return dispatch => {
        FirebaseAuthService.signInWithEmailAndPassword(email, password)
            .then(user => {
                if (user) {
                    dispatch(
                        setUserData({
                            userId: "1",
                            role: "ADMIN",
                            displayName: "Watson Joyce",
                            email: "watsonjoyce@gmail.com",
                            photoURL: "/assets/images/face-7.jpg",
                            age: 25,
                            token: "",
                            ...user
                        })
                    );

                    history.push({
                        pathname: "/"
                    });

                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                } else {
                    return dispatch({
                        type: LOGIN_ERROR,
                        payload: "Login Failed"
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            });
    };
}
