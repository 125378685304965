import React, {Component} from "react";
import {Button} from "@material-ui/core";
import customConstants from "../../../Constants";
import i18n from "i18next";
class NotFound extends Component {

    constructor(props) {
        super(props);

    }

    state = {};

    render() {
        return (
            <div className="flex flex-center flex-middle w-100 h-100vh">
                <div className="flex flex-column flex-center flex-middle" style={{maxWidth: "320px"}}>
                    <img className="mb-32" src="/assets/images/illustrations/loading.svg" height={250} alt=""/>
                    <Button
                        className="capitalize"
                        variant="contained"
                        color="primary"
                    >
                        {i18n.t('Signing, Please Wait...')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default NotFound;
