import {EgretLoadable} from "egret";
import {authRoles} from "../../auth/authRoles";
import {Redirect} from "react-router-dom";
import React from "react";
import ThresholdList from "./ThresholdList";
import SettingsPage from "../sessions/SettingsPage";

const AppList = EgretLoadable({
    loader: () => import("./AppList")
});

let ListRoute = [];

let currentIndex = 0;

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: {show: false}
    },
    secondarySidebar: {show: false},
    footer: {show: false}
};

const landingSettings = {
    ...settings,
    activeLayout: "layout2",
    layout2Settings: {
        mode: "full",
        topbar: {
            show: true
        },
        navbar: {show: false}
    },
    perfectScrollbar: false
}

try {

    if (!localStorage.getItem("grants")) {
        localStorage.setItem("grants", JSON.stringify(["verifications"]));
    }

    const WebSDK = EgretLoadable({
        loader: () => import("../web-sdk/WebSDK")
    });

    let currentGrants = JSON.parse(localStorage.getItem('grants'));

    if (currentGrants !== null && currentGrants.length > 0) {
        const AwaitingList = EgretLoadable({
            loader: () => import("./AwaitingList")
        });

        const SettingsPage = EgretLoadable({
            loader: () => import("../sessions/SettingsPage")
        });

        const ConfirmedList = EgretLoadable({
            loader: () => import("./ConfirmedList")
        });

        const ThresholdList = EgretLoadable({
            loader: () => import("./ThresholdList")
        });

        const CallList = EgretLoadable({
            loader: () => import("./CallList")
        });

        const CallListLive = EgretLoadable({
            loader: () => import("./CallListLive")
        });

        const CustomerList = EgretLoadable({
            loader: () => import("./CustomerList")
        });

        const Landing = EgretLoadable({
            loader: () => import("./Landing")
        });

        const VideoCallScripts = EgretLoadable({
            loader: () => import("./VideoCallScripts")
        });

        const VerificationControl = EgretLoadable({
            loader: () => import("./VerificationControl")
        });

        const VerificationSummary = EgretLoadable({
            loader: () => import("./VerificationSummary")
        });

        const DocumentControl = EgretLoadable({
            loader: () => import("./DocumentControl")
        });

        const DocumentSummary = EgretLoadable({
            loader: () => import("./DocumentSummary")
        });

        const UserList = EgretLoadable({
            loader: () => import("./UserList")
        });

        const ListRoles = EgretLoadable({
            loader: () => import("./ListRoles")
        });

        const ExternalCustomers = EgretLoadable({
            loader: () => import("./ExternalCustomers")
        });

        const QueueReport = EgretLoadable({
            loader: () => import("./QueueReport")
        });

        const AgentReport = EgretLoadable({
            loader: () => import("./AgentReport")
        });

        const QueueMeetingSearch = EgretLoadable({
            loader: () => import("./QueueMeetingSearch")
        });

        const AgentMeetingSearch = EgretLoadable({
            loader: () => import("./AgentMeetingSearch")
        });

        const VerificationReports = EgretLoadable({
            loader: () => import("./VerificationReports")
        });

        const ReportSettings = EgretLoadable({
            loader: () => import("./ReportSettings")
        });

        const ListRouteAvailable = [
            {
                path: "/users/list",
                component: AppList,
                auth: authRoles.admin,
                id: 'verifications',
                settings: landingSettings
            },
            {
                path: "/calls/awaiting",
                component: CallList,
                auth: authRoles.admin,
                id: 'awaiting_calls',
                settings: landingSettings
            },
            {
                path: "/customers/all",
                component: CustomerList,
                auth: authRoles.admin,
                id: 'customers',
                settings: landingSettings
            },
            {
                path: "/documents/awaiting",
                component: AwaitingList,
                auth: authRoles.admin,
                id: 'awaiting_documents',
                settings: landingSettings
            },
            {
                path: "/documents/confirmed",
                component: ConfirmedList,
                auth: authRoles.admin,
                id: 'approved_documents',
                settings: landingSettings
            },
        ];

        window.redirect_uri = "/all";

        ListRouteAvailable.map(function (v, k) {


            if (currentGrants.includes(v.id)) {
                if (currentIndex === 0) {
                    v.exact = true;
                }
                ListRoute.push(v);

                if (v.id === 'awaiting_calls') {

                    ListRoute.push({
                        path: "/calls/video_call",
                        component: CallListLive,
                        auth: authRoles.admin,
                        id: 'video_call',
                        settings: landingSettings
                    });
                    currentIndex++;


                }

                currentIndex++;
            }

        });

        ListRoute.push({
            path: "/web-sdk",
            component: WebSDK,
            auth: authRoles.admin,
            id: 'web-sdk',
            settings
        });

        ListRoute.push({
            path: "/all",
            component: Landing,
            auth: authRoles.admin,
            id: 'landing',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/video_call/scripts",
            component: VideoCallScripts,
            auth: authRoles.admin,
            id: 'vcscripts',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/video_call/controls",
            component: VerificationControl,
            auth: authRoles.admin,
            id: 'verificationcontrol',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/video_call/summary/:id",
            component: VerificationSummary,
            auth: authRoles.admin,
            id: 'verificationsummary',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/documents/controls",
            component: DocumentControl,
            auth: authRoles.admin,
            id: 'documentcontrol',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/documents/summary/:id",
            component: DocumentSummary,
            auth: authRoles.admin,
            id: 'documentsummary',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/users/all",
            component: UserList,
            auth: authRoles.admin,
            id: 'user_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/users/will-be-deleted",
            component: UserList,
            auth: authRoles.admin,
            id: 'user_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/users/will-be-cancelled",
            component: UserList,
            auth: authRoles.admin,
            id: 'user_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/users/will-be-activated",
            component: UserList,
            auth: authRoles.admin,
            id: 'user_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/users/will-be-updated",
            component: UserList,
            auth: authRoles.admin,
            id: 'user_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/roles",
            component: ListRoles,
            auth: authRoles.admin,
            id: 'roles_list',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/customers/external",
            component: ExternalCustomers,
            auth: authRoles.admin,
            id: 'ExternalCustomers',
            settings: landingSettings
        });

        ListRoute.push({
            path: "/thresholds",
            component: ThresholdList,
            auth: authRoles.admin,
            id: 'thresholds',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/settings",
            component: SettingsPage,
            auth: authRoles.admin,
            id: 'settings',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/queue",
            component: QueueReport,
            auth: authRoles.admin,
            id: 'queue_report',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/agent",
            component: AgentReport,
            auth: authRoles.admin,
            id: 'agent_report',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/queue_meeting_search",
            component: QueueMeetingSearch,
            auth: authRoles.admin,
            id: 'queue_meeting_search',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/agent_meeting_search",
            component: AgentMeetingSearch,
            auth: authRoles.admin,
            id: 'agent_meeting_search',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/verification",
            component: VerificationReports,
            auth: authRoles.admin,
            id: 'verifications_report',
            settings: landingSettings
        })

        ListRoute.push({
            path: "/reports/settings",
            component: ReportSettings,
            auth: authRoles.admin,
            id: 'reports_settings',
            settings: landingSettings
        })

    } else {
        ListRoute = [];

        ListRoute.push({
            path: "/web-sdk/demo/",
            component: WebSDK,
            id: 'web-sdk-demo',
            settings
        });

        ListRoute.push({
            path: "/web-sdk/colendi/",
            component: WebSDK,
            id: 'web-sdk-colendi',
            settings
        });
    }




} catch (e) {
    console.log(e);
}


export default ListRoute;
