import { Backdrop, Button, Card, CircularProgress, Grid, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import axios from 'axios';
import customConstants from '../../../Constants';
import { useSnackbar } from 'material-ui-snackbar-provider';

function MFA(props) {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)
    const [end, setEnd] = useState(localStorage.getItem("mfa_end"))
    const snackbar = useSnackbar()

    function format(number) {
        return number < 10 ? "0" + number : number;
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <p onClick={() => resend()} style={{cursor: "pointer", color: "#021633", textDecoration: "underline"}}>{props.t("The code has expired. Resend.")}</p>;
        } else {
            // Render a countdown
            return <p>{props.t("Time left")}: {format(minutes)}:{format(seconds)}</p>;
        }
    };

    const resend = () => {
        const form = new FormData();
        form.append('client_id', localStorage.getItem("username"));
        form.append('client_secret', localStorage.getItem("secret"));
        form.append('grant_type', 'client_credentials');

        axios(
            {
                url: customConstants.API_URL + '/oauth/access_token',
                method: 'POST',
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                },
                data: form
            }
        ).then(function (response) {
            if (response.data.mfa_type === "SMS") {
                const newEnd = Date.now() + customConstants.OTP_TIMEOUT * 1000;
                localStorage.setItem('mfa_end', newEnd);
                setEnd(newEnd);
                snackbar.showMessage(props.t("Verification Code resent"), 'success');
            }
        });
    }

    const submitOtp = () => {
        if (otp.length === 6) {
            setLoading(true)
            const form = new FormData();
            form.append('username', localStorage.getItem("username"));
            form.append('token', otp);

            axios(
                {
                    url: customConstants.API_URL + '/oauth/mfa_token',
                    method: 'POST',
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                    },
                    data: form
                }
            ).then(function (response) {
                if (response.data && response.data.access_token) {
                    localStorage.setItem('jwt_token', response.data.access_token);


                    axios.get(customConstants.API_URL + '/user/grants/WEB', {
                        headers: {
                            "Authorization": `Bearer ${response.data.access_token}`
                        }
                    })
                        .then(function (_response) {
                            let grants = [];
                            _response.data.map(function (v, k) {
                                grants.push(v.serviceName)
                            });

                            localStorage.setItem('grants', JSON.stringify(grants));

                            axios.get(customConstants.API_URL + '/user/me', {
                                headers: {
                                    "Authorization": `Bearer ${response.data.access_token}`
                                }
                            })
                                .then(res => {
                                    setLoading(false)
                                    if (res.data.additionalUserData) {
                                        localStorage.setItem('assistboxId', res.data.additionalUserData.assistboxId);
                                    }

                                    localStorage.removeItem("username");
                                    localStorage.removeItem("secret");
                                    localStorage.removeItem("mfa_end");

                                    setTimeout(function () {
                                        window.location = '/all';
                                    }, 1000);

                                }).catch(function (e) {
                                    setLoading(false)
                                    if (customConstants.SITE_LOGIN === false) {
                                        window.location = customConstants.LOGIN_URL
                                    } else {
                                        window.location = '/session/sign-in';
                                    }
                                });
                        })
                        .catch(function (e) {
                            setLoading(false)
                            if (customConstants.SITE_LOGIN === false) {
                                window.location = customConstants.LOGIN_URL
                            } else {
                                window.location = '/session/sign-in';
                            }
                        });

                }
                else {
                    setLoading(false)
                    snackbar.showMessage(props.t("Invalid OTP Entered"), 'error');
                    setOtp('');
                }
            })
            .catch(function (e) {
                snackbar.showMessage(props.t("Invalid OTP Entered"), 'error');
                setLoading(false)
                console.log(e)
            });
        }
    }

    useEffect(() => {
        if (!(localStorage.getItem("username") && localStorage.getItem("secret") && localStorage.getItem("mfa_end"))) {
            if (customConstants.SITE_LOGIN === false) {
                window.location = customConstants.LOGIN_URL
            } else {
                window.location = '/session/sign-in';
            }
        }
    }, [])


    return (
        <div className="signup flex flex-center w-100 h-100vh" style={{ backgroundColor: '#021633' }}>
            <div className="p-8">
                <Card className="signup-card position-relative y-center" style={{ backgroundColor: '#092A5C' }}>
                    <Grid container>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <div className="p-32 flex flex-center flex-middle h-100">
                                <img src="/assets/images/logos/proveid.svg" alt="" />
                            </div>
                        </Grid>
                        <Grid item lg={7} md={7} sm={7} xs={12} style={{ backgroundColor: '#fff' }}>
                            <div className="p-36 h-100 bg-light-gray position-relative otp" >
                                <h1>{props.t("Verification Code")}</h1>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType="tel"
                                    inputStyle="otp-input"
                                    renderInput={(props) => <input {...props} />}
                                />
                                <div className='otp-buttons'>
                                    <Button onClick={() => setOtp('')} style={{ boxShadow: "inset 0px 0px 0px 2px #999", color: "#999" }}>
                                        {props.t('Clear')}
                                    </Button>
                                    <Tooltip title={otp.length !== 6 ? props.t("Enter 6 characters") : ""}>
                                        <Button onClick={() => submitOtp()} style={{ backgroundColor: "#999", color: "#fff" }}>
                                            {props.t('Confirm')}
                                        </Button>
                                    </Tooltip>
                                </div>
                                <Countdown
                                    key={end}
                                    date={new Date(end * 1)}
                                    renderer={renderer}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            {
                loading &&
                <Backdrop style={{zIndex: 10000}} open={loading}>
                    <CircularProgress color="secondary" />
                </Backdrop>
            }
        </div>
    )
}

export default withTranslation()(MFA)
