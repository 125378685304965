import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  Icon
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { withSnackbar } from 'material-ui-snackbar-provider';

import customConstants from "Constants";
import { withTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const styles = theme => ({
  wrapper: {
    position: "relative",
    backgroundColor:'#fff'
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class ResetPassword extends Component {
  state = {
    password1: "",
    password2: "",
    showPassword1: false,
    showPassword2: false,
    loading: false
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    if (this.state.password1 !== this.state.password2) {
      this.props.snackbar.showMessage(this.props.t("Please enter same password"), 'warning');
      return;
    }
    this.setState({loading: true})
    axios.post(customConstants.API_URL + "/open/password/change", {
        token: (new URL(window.location.href)).searchParams.get("token"),
        newPassword: this.state.password1
    }).then((res) => {
        this.setState({loading: false})
        try {
            if (res.data) {
                this.props.history.push("/session/sign-in")
            }
            else {
              this.props.snackbar.showMessage(this.props.t("Password should be at least 9 characters and include lower case upper case letter number and special character."), 'error');
            }
        } catch (e) {
            console.log(e)
        }
    }).catch((e) => {
        this.setState({loading: false})
        console.log(e)
        this.props.snackbar.showMessage(this.props.t("Could not reset your password"), 'error');
    })
  };
  render() {
    let { password1, password2, showPassword1, showPassword2 } = this.state;
    let { classes } = this.props;

    return (
      <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor:'#021633'}}>
        <div className="p-8">
          <Card className="signup-card position-relative y-center" style={{backgroundColor:'#092A5C'}}>
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/logos/proveid.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12} style={{backgroundColor:'#fff'}}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <h3 style={{marginBottom: "20px"}}>{this.props.t("Create New Password")}</h3>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <div style={{position: "relative"}}>
                      <TextValidator
                        className="mb-16 w-100"
                        label={this.props.t("New Password")}
                        variant="outlined"
                        onChange={this.handleChange}
                        name="password1"
                        type={showPassword1 ? "text" : "password"}
                        value={password1}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                      <span style={{ position: "absolute", right: "15px", top: "15px", cursor: "pointer" }}
                          onClick={() => this.setState({showPassword1: !showPassword1})}
                        >
                        {showPassword1 ? <VisibilityOff /> : <Visibility /> }
                      </span>
                    </div>
                    <div style={{position: "relative"}}>
                      <TextValidator
                        className="mb-16 w-100"
                        label={this.props.t("Confirm New Password")}
                        variant="outlined"
                        onChange={this.handleChange}
                        name="password2"
                        type={showPassword2 ? "text" : "password"}
                        value={password2}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                      <span style={{ position: "absolute", right: "15px", top: "15px", cursor: "pointer" }}
                          onClick={() => this.setState({showPassword2: !showPassword2})}
                        >
                        {showPassword2 ? <VisibilityOff /> : <Visibility /> }
                      </span>
                    </div>
                    <div className="flex flex-middle">
                      <div className={classes.wrapper} style={{width: "100%"}}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.loading}
                          style={{width: "100%"}}
                        >
                          {(!['/session/create-password'].includes(window.location.pathname)) ? this.props.t("Reset Password") : this.props.t("Create Password")}
                        </Button>
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(withSnackbar()(withRouter(
  ResetPassword
))));
