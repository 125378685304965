import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./_index.scss";
import './i18n';
import {SnackbarProvider} from 'material-ui-snackbar-provider'

import * as serviceWorker from "./serviceWorker";
import App from "./app/App";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import TagManager from 'react-gtm-module'
import customConstants from "Constants";

function CloseableSnackbar({message, action, ButtonProps, SnackbarProps, severity}) {

    if (customConstants.GTM) {
        const tagManagerArgs = {
            gtmId: 'GTM-WCWN7X9'
        }

        TagManager.initialize(tagManagerArgs)
    }

    return (
        <Snackbar
            {...SnackbarProps}
            style={{backgroundColor: 'transparent'}}
        >
            <Alert severity={action} style={{padding: 15}}>
                {message}
            </Alert>
        </Snackbar>

    );
}

ReactDOM.render(<SnackbarProvider SnackbarComponent={CloseableSnackbar} SnackbarProps={{autoHideDuration: 4000}}><App/></SnackbarProvider>, document.getElementById("root"));

// for IE-11 support un-comment cssVars() and it's import in this file
// and in EgretTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
