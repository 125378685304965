import React, {Component} from "react";
import {
    Card,
    Grid,
    withStyles,
    MenuItem,
    Select,
    CircularProgress,
    Backdrop
} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {withSnackbar} from 'material-ui-snackbar-provider';
import {withTranslation} from 'react-i18next';
import i18n from "i18next";

import customConstants from "Constants";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";

const styles = theme => ({
    wrapper: {
        position: "relative",
        backgroundColor: '#fff'
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class ListUsers extends Component {
    state = {
        userList: [],
        roles: [],
        loading: false
    };

    componentDidMount() {
        this.setState({ loading: true });
        axios.get(customConstants.API_URL + "/user/role-types")
        .then((res) => {
            try {
                if (res.data) {
                    if(localStorage.getItem("auth_user")) {
                        const user_role = JSON.parse(localStorage.getItem("auth_user")).role.toLowerCase();
                        const user_order = res.data.filter(role => role.roleName == user_role)[0].roleOrder;
                        const roles = res.data.filter(role => role.roleOrder > user_order);
                        this.setState({ roles });
                        this.fetchUsers();
                    }
                } else {
                    this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                    this.setState({ loading: false });
                }
            } catch (e) {
                console.log(e)
                this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                this.setState({ loading: false });
            }
        }).catch((e) => {
            console.log(e)
            this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
            this.setState({ loading: false });
        })
    }

    fetchUsers = () => {
        this.setState({ loading: true });
        axios.get(customConstants.API_URL + "/user/list")
        .then((res) => {
            try {
                if (res.data) {
                    res.data.forEach((el, k) => el.id = k);
                    this.setState({ userList: res.data });
                } else {
                    this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                }
                this.setState({ loading: false });
            } catch (e) {
                console.log(e)
                this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                this.setState({ loading: false });
            }
        }).catch((e) => {
            console.log(e)
            this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
            this.setState({ loading: false });
        })
    }

    handleChange = (event, username) => {
        this.setState({ loading: true });
        const new_role = event.target.value;
        axios.post(customConstants.API_URL + "/user/update-role", {
            username, userRole: new_role
        }).then((res) => {
            try {
                if (res.data) {
                    this.props.snackbar.showMessage(this.props.t(res.data.message), 'success');
                    this.fetchUsers();
                } else {
                    this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                    this.setState({ loading: false });
                }
            } catch (e) {
                console.log(e)
                this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
                this.setState({ loading: false });
            }
        }).catch((e) => {
            console.log(e)
            this.props.snackbar.showMessage(this.props.t("The operation could not be performed due to an error."), 'error');
            this.setState({ loading: false });
        })
    }

    render() {

        const columns = [
            {
                field: 'username', headerName: this.props.t('Username'),
                width: 200,
            },
            {
                field: 'createdAt', headerName: this.props.t('Registration Date'), width: 200,
                renderCell(params) {
                    return moment(params.row.createdAt).local().locale(i18n.language).format('DD/MM/YYYY, HH:mm')
                }
            },
            {
                field: 'role', headerName: this.props.t('Role'), sortable: false,
                width: 150, filterable: false,
                renderCell: (params) => {
                    return <>
                        <Select
                            value={params.row.role}
                            onChange={e => this.handleChange(e, params.row.username)}
                            fullWidth
                        >
                            {
                                this.state.roles.map((role) => <MenuItem value={role.roleName}>{this.props.t(role.roleName)}</MenuItem>)
                            }
                        </Select>
                    </>
                }
            }
        ];

        return (
            <div className="signup flex flex-center w-100 h-100vh" style={{backgroundColor: '#021633'}}>
                {(this.state.loading === true) ?
                    <Backdrop style={{
                        zIndex: 999,
                        color: '#fff'
                    }} open={this.state.loading}><CircularProgress
                        color="secondary"/></Backdrop> : null}
                <div className="p-8">
                    <Card className="signup-card position-relative y-center" style={{backgroundColor: '#092A5C', minWidth: '1000px'}}>
                        <Grid container>
                            <Grid item xs={12} style={{backgroundColor: '#fff', height: '88vh'}}>
                                <div className="p-36 h-100 position-relative">
                                    <h3 style={{marginBottom: "20px"}}>{this.props.t("Team Members")}</h3>
                                    <div style={{height: 'calc(88vh - 110px)', width: '100%'}}>
                                    <DataGrid rowHeight={60} rows={this.state.userList} columns={columns} pageSize={50}
                                        autoHeight={false}
                                        disableSelectionOnClick={true}
                                     />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles, {withTheme: true})(withSnackbar()(withRouter(
    ListUsers
))));
